import { Box, Button, Text } from "@chakra-ui/react";
import MonacoEditor from "@monaco-editor/react";
import StartSession from "src/components/modal/playground/start-session.modal";
import StopSession from "src/components/modal/playground/stop-session.modal";
import GetSession from "src/components/modal/playground/get-session.modal";
import { useAppSelector } from "src/redux";
import {
  CURL_START_SESSION,
  CURL_STOP_SESSION,
  CURL_GET_SESSION,
} from "src/constants/playground";
import { useState, useEffect } from "react";
import {
  useStartSessionMutation,
  useStopSessionMutation,
  useLazyGetSessionByIdQuery,
} from "src/hook/session/session";

const PlaygroundComponent = () => {
  const token = localStorage.getItem("token");
  const [defaultValue, setDefaultValue] = useState<any>();
  const [response, setResponse] = useState<any>();
  const options = {
    readOnly: true,
  };

  enum ModalType {
    START_SESSION = "START_SESSION",
    STOP_SESSION = "STOP_SESSION",
    GET_SESSION = "GET_SESSION",
  }

  const [startSession, { isLoading: loadingStart }] = useStartSessionMutation();
  const [stopSession, { isLoading: loadingStop }] = useStopSessionMutation();
  const [modelOpen, setModelOpen] = useState<ModalType | "">("");
  const handleCloseModal = () => {
    setModelOpen("");
  };

  const [dataSessionById, setDataSessionById] = useState([])

  const idSession = useAppSelector((state) => state?.playground?.dataSession);
  const idConnector = useAppSelector((state) => state?.playground?.data);
  const situation = useAppSelector((state) => state?.playground?.case);
  const [getSessionById] = useLazyGetSessionByIdQuery();

  const handleTryItButton = () => {
    if (situation === "session") {
      setResponse(JSON.stringify(dataSessionById, null, 2));
    } else if (situation === "start") {
      startSession({ connector: { id: idConnector } }).then((res) => {
        setResponse(JSON.stringify(res, null, 2));
      });
    } else if (situation === "stop") {
      stopSession({ connector: { id: idConnector } }).then((res) => {
        setResponse(JSON.stringify(res, null, 2));
      });
    }
  };

  useEffect(() => {
    if(idSession?.length) {
      getSessionById(idSession).unwrap().then((data) => {
        setDataSessionById(data)
      })
    }
  }, [idSession])

  useEffect(() => {
    switch (situation) {
      case "start":
        setDefaultValue(CURL_START_SESSION(token, idConnector));
        break;
      case "stop":
        setDefaultValue(CURL_STOP_SESSION(token, idConnector));
        break;
      case "session":
        setDefaultValue(CURL_GET_SESSION(idSession, token));
        break;
      default:
    }
  }, [situation, idConnector, idSession]);

  return (
    <Box w={"full"}>
      <Box
        w={"full"}
        h={"full"}
        bgColor={"#FBFBFB"}
        overflow={"hidden"}
        p={"74px 90px 74px 40px"}
      >
        <Box mb={"40px"}>
          <Text
            fontSize={"38px"}
            lineHeight={"52px"}
            fontWeight={"500"}
            color={"#171E38"}
            w={"fit-content"}
            fontFamily={"Avenir Black"}
          >
            Playground
          </Text>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          mb={"40px"}
          gap={"60px"}
        >
          <Button
            fontSize={"20px"}
            fontFamily={"Avenir Black"}
            w={"250px"}
            h={"55px"}
            borderRadius={"28px"}
            border={"1px solid #1f2c4f"}
            bg={"unset"}
            mr={"14px"}
            onClick={() => {
              setModelOpen(ModalType.START_SESSION);
            }}
          >
            <Text>Try start session API</Text>
          </Button>
          <Button
            fontSize={"20px"}
            fontFamily={"Avenir Black"}
            w={"250px"}
            h={"55px"}
            borderRadius={"28px"}
            border={"1px solid #1f2c4f"}
            bg={"unset"}
            mr={"14px"}
            onClick={() => {
              setModelOpen(ModalType.STOP_SESSION);
            }}
          >
            <Text>Try stop session API</Text>
          </Button>
          <Button
            fontSize={"20px"}
            fontFamily={"Avenir Black"}
            w={"250px"}
            h={"55px"}
            borderRadius={"28px"}
            border={"1px solid #1f2c4f"}
            bg={"unset"}
            mr={"16px"}
            onClick={() => {
              setModelOpen(ModalType.GET_SESSION);
            }}
          >
            <Text>Try get session API</Text>
          </Button>
          {/* <Button
            fontSize={"20px"}
            fontFamily={"Avenir Black"}
            w={"250px"}
            h={"55px"}
            borderRadius={"28px"}
            border={"1px solid #1f2c4f"}
            bg={"unset"}
            mr={"14px"}
          >
            <Text>Get token</Text>
          </Button> */}
        </Box>
        <Box
          mb={"40px"}
          p={"10px"}
          border={"2px solid #1e1e1e"}
          maxW={"100% !important"}
          bgColor={"#1e1e1e"}
          borderRadius={"8px"}
        >
          <Text
            fontSize={"14px"}
            fontFamily={"sans-serif"}
            fontWeight={"500"}
            color={"#c6cbd3"}
            lineHeight={"22px"}
          >
            REQUEST
          </Text>
          <Box borderBottom={"2px solid #282a2e"} mb={"8px"} />
          <MonacoEditor
            theme={"vs-dark"}
            height="200px"
            language="javascript"
            value={defaultValue}
            options={options}
          />
          <Box borderBottom={"2px solid #282a2e"} mb={"8px"} />
          <Box display={"flex"} justifyContent={"center"}>
            <Button
              border={"1px solid #d9d9d9"}
              color={"#d9d9d9"}
              height={"32px"}
              bg={"unset"}
              borderRadius={"2px"}
              onClick={handleTryItButton}
              isLoading={loadingStart || loadingStop}
              isDisabled={loadingStart || loadingStop}
            >
              Try it
            </Button>
          </Box>
        </Box>
        <Box
          p={"10px"}
          border={"2px solid #1e1e1e"}
          maxW={"100% !important"}
          bgColor={"#1e1e1e"}
          borderRadius={"8px"}
        >
          <Text
            fontSize={"14px"}
            fontFamily={"sans-serif"}
            fontWeight={"500"}
            color={"#c6cbd3"}
            lineHeight={"22px"}
          >
            RESPONSE
          </Text>
          <Box borderBottom={"2px solid #282a2e"} mb={"8px"} />
          <MonacoEditor
            theme={"vs-dark"}
            height="400px"
            language="javascript"
            value={response}
            options={options}
          />
        </Box>
      </Box>

      <StartSession
        isOpen={modelOpen === ModalType.START_SESSION}
        onClose={handleCloseModal}
      />

      <StopSession
        isOpen={modelOpen === ModalType.STOP_SESSION}
        onClose={handleCloseModal}
      />

      <GetSession
        isOpen={modelOpen === ModalType.GET_SESSION}
        onClose={handleCloseModal}
      />
    </Box>
  );
};

export default PlaygroundComponent;

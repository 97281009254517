import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  Input,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useRef } from "react";
import { FormValues } from "./add-new-user.type";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./add-new-user.schema";
import { useCreateUserMutation, useGetUserRolesQuery } from "src/hook/user/user";
import { getToastAttribute, ToastAttribute } from "src/constants";
import { useValidatePasswords } from "src/constants/validate";
import { useGetListCompanyQuery } from "src/hook/company/company";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
}

const AddNewUserComponent = (props: Props) => {
  const { isOpen, onClose, refetch } = props;
  const cancelRef = useRef();
  const toast = useToast();
  const showToast = (props: ToastAttribute) => {
    toast(getToastAttribute(props));
  };

  const { updatePassword } = useValidatePasswords();
  const [createUser, { isLoading }] = useCreateUserMutation();
  const { data: userRoles } = useGetUserRolesQuery();
  const { data: companies } =
    useGetListCompanyQuery();

  const {
    register,
    trigger,
    handleSubmit,
    reset: resetForm,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      phoneNumber: "",
      role: { id: 2 },
      company: { id: '' },
      active: "true",
    },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const handleSubmitForm = (data: FormValues) => {
    createUser({ ...data, role: { id: Number(data.role) }, active: data.active === "true" ? true : false })
      .unwrap()
      .then(() => {
        showToast({
          title: "User created.",
          dis: "User created successfully.",
        });
        resetForm();
        refetch();
        onClose();
      })
      .catch((err) => {
        showToast({
          title: "User created.",
          dis: err?.data?.message,
          sts: "error",
        });
      });
  };

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef as any}
        onClose={onClose}
      >
        <AlertDialogOverlay bg={"#052A6E"} opacity={"0.7 !important"} />
        <AlertDialogContent
          minW={"56%"}
          color={"#171E38"}
          bg={"#F2F5FC"}
          top={"50%"}
          // left={"50%"}
          transform={"translateY(-50%) !important"}
          m={0}
        >
          <AlertDialogCloseButton
            _focusVisible={{
              outline: "none",
              border: "none",
            }}
            size={"lg"}
          />
          <AlertDialogBody pt={"38px"} pl={"40px"}>
            <Text
              fontSize={"40px"}
              fontFamily={"Avenir Black"}
              mb={"39px"}
              w={"fit-content"}
            >
              Add user
            </Text>
            <form onSubmit={handleSubmit(handleSubmitForm)} autoComplete="new-password">
              <Input autoComplete="false" name="hidden" type="text" display={"none"} />
              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  First name<span style={{ color: "#E74D3D" }}>*</span>
                </Text>
                <Box w={"60%"}>
                  <Input
                    border={"0px"}
                    bg={"white"}
                    borderRadius={"10px"}
                    height={"45px"}
                    fontFamily={"Avenir Book"}
                    fontSize={"18px"}
                    {...register("firstName", {
                      shouldUnregister: true,
                    })}
                  />
                  {errors.firstName && (
                    <Text
                      fontSize={"12px"}
                      mt={"4px"}
                      ml={"4px"}
                      color={"red.400"}
                      fontFamily={"Avenir Medium"}
                    >
                      {errors.firstName.message}
                    </Text>
                  )}
                </Box>
              </Box>

              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  Last name<span style={{ color: "#E74D3D" }}>*</span>
                </Text>
                <Box w={"60%"}>
                  <Input
                    border={"0px"}
                    bg={"white"}
                    borderRadius={"10px"}
                    height={"45px"}
                    fontFamily={"Avenir Book"}
                    fontSize={"18px"}
                    {...register("lastName", {
                      shouldUnregister: true,
                    })}
                  />
                  {errors.lastName && (
                    <Text
                      fontSize={"12px"}
                      mt={"4px"}
                      ml={"4px"}
                      color={"red.400"}
                      fontFamily={"Avenir Medium"}
                    >
                      {errors.lastName.message}
                    </Text>
                  )}
                </Box>
              </Box>

              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  Email<span style={{ color: "#E74D3D" }}>*</span>
                </Text>
                <Box w={"60%"}>
                  <Input
                    border={"0px"}
                    bg={"white"}
                    borderRadius={"10px"}
                    height={"45px"}
                    fontFamily={"Avenir Book"}
                    fontSize={"18px"}
                    {...register("email", {
                      shouldUnregister: true,
                    })}
                    autoComplete="new-password"
                  />
                  {errors.email && (
                    <Text
                      fontSize={"12px"}
                      mt={"4px"}
                      ml={"4px"}
                      color={"red.400"}
                      fontFamily={"Avenir Medium"}
                    >
                      {errors.email.message}
                    </Text>
                  )}
                </Box>
              </Box>
              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  Password<span style={{ color: "#E74D3D" }}>*</span>
                </Text>
                <Box w={"60%"}>
                  <Input
                    fontFamily={"Avenir Book"}
                    bg={"white"}
                    borderRadius={"lg"}
                    border={
                      errors.password
                        ? "1px solid #E74D3D"
                        : "1px solid #ffffff"
                    }
                    height={"2.75rem"}
                    _focusVisible={{
                      border:
                        errors.password
                          ? "1px solid #E74D3D"
                          : "1px solid #ffffff",
                    }}
                    _placeholder={{
                      color: "#BEC3C7",
                    }}
                    type={"password"}
                    fontSize={"18px"}
                    p={"10px 17px"}
                    {...register("password", {
                      shouldUnregister: true,
                      onChange: (e) => {
                        updatePassword(e.target.value);
                        trigger("password");
                      }
                    })}
                    _hover={{
                      border:
                        errors.password
                          ? "1px solid #E74D3D"
                          : "1px solid #ffffff",
                    }}
                    autoComplete="off"
                  />
                  {errors.password && (
                    <Text
                      fontSize={"14px"}
                      fontFamily={"Avenir Book"}
                      color={"#E74D3D"}
                    >
                      {errors.password.message}
                    </Text>
                  )}
                </Box>
              </Box>

              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  Mobile number
                </Text>
                <Input
                  w={"60%"}
                  border={"0px"}
                  bg={"white"}
                  borderRadius={"10px"}
                  height={"45px"}
                  fontFamily={"Avenir Book"}
                  fontSize={"18px"}
                  {...register("phoneNumber", {
                    shouldUnregister: true,
                  })}
                />
              </Box>

              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  Role
                </Text>

                <Select
                  w={"60%"}
                  border={"0px"}
                  bg={"white"}
                  borderRadius={"10px"}
                  variant={"base"}
                  size={"lg"}
                  textTransform={"capitalize"}
                  height={"45px"}
                  fontFamily={"Avenir Book"}
                  fontSize={"18px"}
                  {...register("role", {
                    shouldUnregister: true,
                  })}
                  defaultValue={2}
                >
                  {userRoles?.data?.map(role => {
                    return <option key={role.id} value={role.id}>{role.name}</option>
                  })}
                </Select>
              </Box>
              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  Company name
                </Text>
                <Box w={"60%"}>
                  <Select
                    border={"0px"}
                    bg={"white"}
                    borderRadius={"10px"}
                    variant={"base"}
                    size={"lg"}
                    textTransform={"capitalize"}
                    height={"45px"}
                    fontFamily={"Avenir Book"}
                    fontSize={"18px"}
                    {...register("company.id", {
                      shouldUnregister: true,
                    })}
                  >
                    {companies?.data?.map((item) => (
                      item?.companyName && <option key={item?.id} value={item?.id}>{item?.companyName}</option>
                    ))}
                  </Select>
                  {errors.company && (
                    <Text
                      fontSize={"12px"}
                      mt={"4px"}
                      ml={"4px"}
                      color={"red.400"}
                      fontFamily={"Avenir Medium"}
                    >
                      {errors.company.message}
                    </Text>
                  )}
                </Box>
              </Box>
              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  Status
                </Text>

                <Select
                  w={"60%"}
                  border={"0px"}
                  bg={"white"}
                  borderRadius={"10px"}
                  variant={"base"}
                  size={"lg"}
                  textTransform={"capitalize"}
                  height={"45px"}
                  fontFamily={"Avenir Book"}
                  fontSize={"18px"}
                  {...register("active", {
                    shouldUnregister: true,
                  })}
                >
                  <option value={"true"}>Active</option>
                  <option value={"false"}>Inactive</option>
                </Select>
              </Box>

              <Box
                mt={"40px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                fontFamily={"Avenir Black"}
                mb={"25px"}
              >
                <Button
                  type="submit"
                  variant={"solid"}
                  bg={"#0A47BA"}
                  w={"191px"}
                  height={"55px"}
                  borderRadius={"37px"}
                  mr={"16px"}
                  color={"#FFFFFF"}
                  fontSize={"20px"}
                  _hover={{
                    bg: "#0A47BA",
                    opacity: 0.8,
                  }}
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  Create
                </Button>
              </Box>
            </form>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default AddNewUserComponent;

import { useState } from "react";
import { Box, Button, Input, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useAppDispatch } from "src/redux";

import { FormValues } from "./forgot-password.type";
import schema from "./email.schema";
import { useForgotPasswordUserMutation } from "src/hook/auth/auth";
import { handleCollapse } from "src/hook/sidebar/sidebar";

export interface ForgotPasswordProps {
  setEmail: (email: string) => void;
  setStep: () => void;
}

const ForgotPasswordComponent = ({ setEmail, setStep }: ForgotPasswordProps) => {
  const [shouldValidateEmail, setShouldValidateEmail] = useState(false);
  const [messError, setMessError] = useState("");

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const dispatch = useAppDispatch();
  const [forgotPasswordUser, { isLoading }] = useForgotPasswordUserMutation();

  const validateEmail = (value: string) => {
    if (shouldValidateEmail && value.trim() !== "") {
      try {
        schema.validateSyncAt("email", { email: value });
      } catch (err) {
        return (err as any).message;
      }
    }
    return true;
  };

  const handleEmailBlur = () => {
    setShouldValidateEmail(true);
  };

  const submitForm = (value: FormValues) => {
    setMessError("");
    forgotPasswordUser(value)
      .unwrap()
      .then(() => {
        dispatch(handleCollapse({ status: true }));
        setEmail(value?.email)
        setStep()
      })
      .catch((error) =>
        setMessError(() => error?.data?.message)
      );
  };

  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        mx={"auto"}
        mb={"16px"}
        color={"orange.600"}
        px={"20px"}
        py={"5px"}
        w={"fit-content"}
        bgColor={"gray.200"}
        borderRadius={"md"}
        opacity={messError?.length > 0 ? "1" : "0"}
        height={"34px"}
      >
        <Text fontFamily={"Avenir Heavy"}>{messError}</Text>
      </Box>
      <Box
        maxW={"565px"}
        px={"62px"}
        pt={"40px"}
        pb={"30px"}
        bg={"#F2F5FC"}
        display={"flex"}
        justifyContent={"center"}
        rounded={"10px"}
        color={"#020D21"}
      >
        <Box w={"full"}>
          <Box textAlign={"center"}>
            <Text
              fontSize={"40px"}
              fontFamily={"Avenir Black"}
              lineHeight={"55px"}
            >
              Forgot password
            </Text>
            <Text fontFamily={"Avenir Book"}>Please enter your details</Text>
          </Box>

          <form onSubmit={handleSubmit(submitForm)}>
            <Box mb={"24px"}>
              <Text fontFamily={"Avenir Heavy"} mb={"4px"} fontSize={"18px"}>
                Email
              </Text>
              <Input
                fontFamily={"Avenir Book"}
                bg={"white"}
                borderRadius={"lg"}
                border={
                  (errors.email && shouldValidateEmail) || messError?.length > 0
                    ? "1px solid #E74D3D !important"
                    : "1px solid #ffffff !important"
                }
                placeholder={"Enter your email"}
                height={"2.75rem"}
                _placeholder={{
                  color: "#BEC3C7",
                }}
                _focusVisible={{
                  boxShadow: "none",
                }}
                fontSize={"18px"}
                p={"10px 17px"}
                {...register("email", {
                  shouldUnregister: true,
                  onBlur: handleEmailBlur,
                  validate: validateEmail,
                  onChange: () => {
                    trigger("email");
                  },
                })}
                id={"email"}
              />
              {errors.email && shouldValidateEmail && (
                <Text
                  fontSize={"14px"}
                  fontFamily={"Avenir Book"}
                  color={"#E74D3D"}
                >
                  {errors.email.message}
                </Text>
              )}
            </Box>
            <Box mx={"13px"}>
              <Button
                fontFamily={"Avenir Black"}
                background={"#0A47BA"}
                borderRadius={"full"}
                h={"54px"}
                isLoading={isLoading}
                fontSize={"20px"}
                w={"full"}
                color={"white"}
                _hover={{
                  opacity: 0.8,
                }}
                _disabled={{
                  bg: "#BEC3C7",
                  _hover: {
                    bg: "#BEC3C7",
                    opacity: "1",
                  },
                }}
                isDisabled={!isValid}
                type={"submit"}
              >
                Next
              </Button>
            </Box>
            <Box
              mt={"16px"}
              textAlign={"center"}
              display={"flex"}
              justifyContent={"center"}
            >
              <Text
                fontFamily={"Avenir Book"}
                pl={"4px"}
              >
                Remember password?
              </Text>
              <Link to={"/login"}>
                <Text
                  color={"#0A47BA"}
                  textDecoration={"underline"}
                  fontWeight={"900"}
                  px={"10px"}
                  position={"relative"}
                  fontFamily={"Avenir Medium"}
                >
                  Login here
                </Text>
              </Link>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPasswordComponent;

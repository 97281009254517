export enum ChargerHistoryApi {
  GET_CHARGER_HISTORY = "/charge-station-history",
  EXPORT_CHARGER_HISTORY_CSV = "/charge-station-history/export"
}

export interface CHARGER_HISTORY_PARAMS {
  page?: number;
  take?: number;
  search?: string;
  companyIdList?: string[];
  statusList?: string[];
  chargeStationIdList?: string[];
  startDay?: string;
  stopDay?: string;
  timezone?: string;
}
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  Icon,
  Input,

  Text,
  useToast,
} from "@chakra-ui/react";
import { useRef, useState, } from "react";
import { HiPlusCircle, HiMinusCircle } from "react-icons/hi";
import { FormValues } from "./start-connector.type"
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./start-connector.schema";

import { getToastAttribute, ToastAttribute } from "src/constants";
import { useStartSessionMutation } from "src/hook/session/session";
import { ConnectorType } from "src/template/network/charge-stations/charge-stations.type";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  connectorId: ConnectorType | null;
}

const StartConnectorComponent = (props: Props) => {
  const { isOpen, onClose, connectorId, refetch: getListChargeStation } = props;
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(1)
  const cancelRef = useRef();
  const toast = useToast();
  const showToast = (props: ToastAttribute) => {
    toast(getToastAttribute(props));
  };

  const [startSession, { isLoading }] =
    useStartSessionMutation();

  const {
    register,
    handleSubmit,
    reset: resetForm,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      hours: 0,
      minutes: 1
    },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const handleSubmitForm = (value: boolean) => {
    let duration = (hours && hours * 60) + minutes
    if (!value) duration = 0

    startSession({ connector: { id: connectorId }, ...(duration !== 0 && { duration: duration }) })
      .unwrap()
      .then(() => {
        showToast({
          title: "Start session.",
          dis: "Start session successfully.",
        });
        onClose();
        getListChargeStation();
      })
      .catch((error) => {
        showToast({
          title: "Connector creation.",
          dis: error?.data?.message ?? "Start session failed.",
          sts: "error",
        });
      });
  };

  const handleChangeHours = (value: number) => {
    if (value < 0) return
    setHours(value);
  }

  const handleChangeMinutes = (value: number) => {
    if (value < 1 && hours === 0) return
    if (value < 1 && hours) {
      setHours(hours - 1);
      setMinutes(60 + value)
    } else if (value >= 60) {
      const hoursNumber = Math.floor(value / 60)
      setHours(hours + hoursNumber);
      setMinutes(value - hoursNumber * 60)
    } else {
      setMinutes(value)
    }
  }

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef as any}
        onClose={onClose}

      >
        <AlertDialogOverlay bg={"#052A6E"} opacity={"0.7 !important"} />
        <AlertDialogContent
          width={"50%"}
          minWidth={"700px"}
          maxWidth={"900px"}
          color={"#171E38"}
          bg={"#F2F5FC"}
          top={"50%"}
          transform={"translateY(-50%) !important"}
          m={0}
        >
          <AlertDialogCloseButton
            _focusVisible={{
              outline: "none",
              border: "none",
            }}
            size={"lg"}
          />
          <AlertDialogBody pt={"38px"} pl={"40px"} >
            <Text
              fontSize={"40px"}
              fontFamily={"Avenir Black"}
              mb={"39px"}
              w={"fit-content"}
            >
              Set Charging Duration
            </Text>
            <form >
              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"30%"}>
                  Charging Duration<span style={{ color: "#E74D3D" }}>*</span>
                </Text>
                <Box w={"50%"} >
                  <Box position={"relative"} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                    <Icon as={HiMinusCircle} width={"50px"} height={"50px"} mr={"8px"} color={"#0A47BA"} cursor={"pointer"} onClick={() => { handleChangeMinutes(minutes - 2) }} />
                    <Input
                      type="number"
                      border={"0px"}
                      bg={"white"}
                      style={{ borderRadius: 0, borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }}
                      height={"45px"}
                      fontFamily={"Avenir Book"}
                      fontSize={"18px"}
                      {...register("hours", {
                        shouldUnregister: true,
                      })}
                      value={hours}
                      onChange={(e) => { handleChangeHours(Number(e.target.value)) }}
                    />
                    <Text
                      position={"absolute"}
                      left={"40%"}
                      zIndex={5}
                      fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"fit-content"}
                    >
                      hr
                    </Text>
                    <Input
                      type="number"
                      border={"0px"}
                      bg={"white"}
                      style={{ borderRadius: 0, borderTopRightRadius: "10px", borderBottomRightRadius: "10px", borderTopLeftRadius: 0 }}
                      height={"45px"}
                      fontFamily={"Avenir Book"}
                      fontSize={"18px"}
                      {...register("minutes", {
                        shouldUnregister: true,
                      })}
                      value={minutes}
                      onChange={(e) => { handleChangeMinutes(Number(e.target.value)) }}
                    />
                    <Icon as={HiPlusCircle} width={"50px"} height={"50px"} ml={"8px"} color={"#0A47BA"} cursor={"pointer"} onClick={() => { handleChangeMinutes(minutes + 2) }} />
                    <Text
                      position={"absolute"}
                      right={"22%"}
                      zIndex={5}
                      fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"fit-content"}
                    >
                      min
                    </Text>
                  </Box>
                  {errors.minutes && (
                    <Text
                      fontSize={"12px"}
                      mt={"4px"}
                      ml={"4px"}
                      color={"red.400"}
                      fontFamily={"Avenir Medium"}
                    >
                      {errors.minutes.message}
                    </Text>
                  )}
                </Box>
              </Box>



              <Box
                mt={"40px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                fontFamily={"Avenir Black"}
                mb={"25px"}
              >
                <Button
                  variant={"outline"}
                  borderColor={"#0A47BA"}
                  w={"191px"}
                  height={"55px"}
                  borderRadius={"37px"}
                  mr={"16px"}
                  fontSize={"20px"}
                  _hover={{
                    bg: "#BEC3C7",
                    opacity: 0.8,
                  }}
                  onClick={() => { onClose(); resetForm() }}
                >
                  Discard
                </Button>
                <Button
                  type="submit"
                  variant={"solid"}
                  bg={"#0A47BA"}
                  w={"191px"}
                  height={"55px"}
                  borderRadius={"37px"}
                  mr={"16px"}
                  color={"#FFFFFF"}
                  fontSize={"20px"}
                  _hover={{
                    bg: "#0A47BA",
                    opacity: 0.8,
                  }}
                  isDisabled={!minutes && !hours || isLoading}
                  isLoading={isLoading}
                  _disabled={{
                    bg: "#BEC3C7",
                    _hover: {
                      bg: "#BEC3C7",
                      opacity: "1",
                    },
                  }}
                  onClick={handleSubmit(() => handleSubmitForm(true))}
                >
                  Apply
                </Button>
                <Button
                  type="submit"
                  variant={"solid"}
                  bg={"#0A47BA"}
                  w={"291px"}
                  height={"55px"}
                  borderRadius={"37px"}
                  mr={"16px"}
                  color={"#FFFFFF"}
                  fontSize={"20px"}
                  _hover={{
                    bg: "#0A47BA",
                    opacity: 0.8,
                  }}
                  isDisabled={!minutes && !hours || isLoading}
                  isLoading={isLoading}
                  _disabled={{
                    bg: "#BEC3C7",
                    _hover: {
                      bg: "#BEC3C7",
                      opacity: "1",
                    },
                  }}
                  onClick={handleSubmit(() => handleSubmitForm(false))}
                >
                  Start with no duration
                </Button>
              </Box>
            </form>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default StartConnectorComponent;

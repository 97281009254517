import { SelectType } from "src/template/logs/sessions/session.type";

export enum UserApi {
  GET_LIST_USER = "/users",
  CREATE_USER = "/users/create",
  UPDATE_USER = "/users",
  DELETE_USER = "/users",
  GET_USER = "/users/detail",
  GET_ROLE = "/role",
  EXPORT_USER_CSV = "/users/export",
}

export interface BODY_UPDATE_USER {
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  role: { id: string | number; name?: string };
  active: boolean;
  id: string;
  companyId?: string;
}

export interface BODY_CREATE_USER {
  email: string;
  firstName: string;
  lastName: string;
  password?: string;
  phoneNumber?: string;
  role?: { id: number };
  active?: boolean;
}

export interface BODY_GET_LIST_USER {
  take?: number | "full";
  page?: number | "full";
  companyId?: string;
  roleId?: SelectType[];
  statusList?: SelectType[];
}

interface COMPANY {
  companyName?: string;
  createdAt: string;
  email?: string;
  id: string;
  phoneNumber?: string | number;
  updatedAt: string

}

export interface USER {
  active: boolean;
  addressId?: null;
  company: COMPANY;
  createdAt?: string;
  email: string;
  emailConfig: boolean;
  emailTimeDelay: number;
  firstName?: string;
  id: string;
  identity: string;
  lastName?: string;
  password?: null | string;
  phoneNumber?: string;
  role: string;
  updatedAt?: string;
}

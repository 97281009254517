import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axios from "axios";
import { baseQueryWithReauth } from "src/services";

import {
  GET_LIST_SESSION_TYPE,
  SessionApi,
} from "./session.type";

const handleSessionParams = (data: GET_LIST_SESSION_TYPE) => {
  let temp = ""
  data?.listLocation?.forEach((item) => {
    if (item?.check) {
      temp += "&locationId[]=" + item?.value
    }
  })
  data?.listCS?.forEach((item) => {
    if (item?.check) {
      temp += "&chargeStationId[]=" + item?.value
    }
  })
  data?.listConnector?.forEach((item) => {
    if (item?.check) {
      temp += "&connectorId[]=" + item?.value
    }
  })
  data?.companyIdList?.forEach((item) => {
    if (item) {
      temp += "&companyId[]=" + item
    }
  })
  data?.startDay && (temp += "&startDay=" + data.startDay)
  data?.stopDay && (temp += "&stopDay=" + data.stopDay)
  data?.timezone && (temp += "&timezone=" + data.timezone)
  return temp
}

export const sessionAPI = createApi({
  reducerPath: "sessionAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    startSession: builder.mutation<any, any>({
      query: (body) => ({
        url: SessionApi.START_SESSION,
        method: "POST",
        body: body,
      }),
    }),
    stopSession: builder.mutation<any, any>({
      query: (body) => ({
        url: SessionApi.STOP_SESSION,
        method: "PATCH",
        body: body,
      }),
    }),
    getSessionById: builder.query<any, void>({
      query: (id) => ({
        url: `${SessionApi.GET_SESSION_BY_ID}/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),

    getListSession: builder.query<any, GET_LIST_SESSION_TYPE>({
      query: (data) => {
        const temp = handleSessionParams(data)
        return {
          url: `${SessionApi.GET_LIST_SESSION}?take=${data?.take || 10}&page=${data?.page || 1}${temp}`,
          method: "GET",
        }
      },
      keepUnusedDataFor: 0
    })
  }),
});

export const {
  useStartSessionMutation,
  useStopSessionMutation,
  useGetSessionByIdQuery,
  useLazyGetSessionByIdQuery,
  useLazyGetListSessionQuery
} = sessionAPI;


export const downloadSessionCsv = async (data: GET_LIST_SESSION_TYPE) => {
  let result = handleSessionParams(data)
  if (result.length > 0) {
    result = "?" + result.slice(1)
  }
  return await axios({
    method: 'GET',
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    url: `${process.env.REACT_APP_API_URL}${SessionApi.DOWNLOAD_CSV}${result}`,
    responseType: 'blob'
  })
    .then(response => {
      if (response) {
        const file = new Blob([response.data], { type: 'image/png' })
        return file
      }
      return Promise.reject();
    });
}
import { Box, Button, Text, Icon, useDisclosure, useToast, Tooltip, Flex } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";

import TableComponent from "src/components/custom-table";
import { HiPlus } from "react-icons/hi";
import { ReactComponent as DownloadIcon } from "src/assets/icon/download-icon.svg";
import { ActiveStatusList, RowType, UserRoleList } from "./user-settings.type";
import { FiEdit3 } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import SuccessDeleteComponent from "src/components/modal/success-delete/success-delete.modal";
import AddNewUserComponent from "src/components/modal/add-new-user/add-new-user.modal";
import ConfirmDeleteUserComponent from "src/components/modal/confirm-delete-user/confirm-delete-user.modal";
import { useGetListUserQuery, exportUserCsv } from "src/hook/user/user";
import UpdateUserComponent from "src/components/modal/update-user/update-user.modal";
import { useAppSelector } from "src/redux";
import { downloadCsv } from "src/constants/download-csv";
import { PaginationType } from "src/constants";
import { SelectType } from "src/template/logs/sessions/session.type";
import { MultiSelectComponent } from "src/components/multi-select/multi-select.component";

const UsersSettingComponent = () => {
  const [filterParams, setFilterParams] = useState<string>("");
  const [idUser, setIdUser] = useState();
  const [isExportLoading, setExportLoading] = useState(false);
  const [userRole, setUserRole] =
    useState<SelectType[]>(UserRoleList);
  const [activeStatus, setActiveStatus] =
    useState<SelectType[]>(ActiveStatusList);
  const [pageIndex, setPageIndex] = useState<PaginationType>({
    page: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    pageCount: 0,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenConfirmDelete,
    onOpen: onOpenConfirmDelete,
    onClose: onCloseConfirmDelete,
  } = useDisclosure();
  const {
    isOpen: isOpenSuccess,
    onOpen: onOpenSuccess,
    onClose: onCloseSuccess,
  } = useDisclosure();
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();

  const filterCompanies = useAppSelector((state) => state?.headerFilter?.companyId)
  const { data, refetch } = useGetListUserQuery({ companyId: filterParams, page: pageIndex?.page, roleId: userRole, statusList: activeStatus });
  const toast = useToast();

  const handleExportAction = async () => {
    setExportLoading(true);
    await exportUserCsv(
      { companyId: filterParams, roleId: userRole, statusList: activeStatus })
      .then((data) => downloadCsv(data, "user-settings"))
      .catch(() => {
        toast({
          title: "Export csv",
          description: "Export csv failed",
          duration: 2000,
          status: "error",
          position: "top",
        });
      });
    setExportLoading(false);
  };

  useEffect(() => {
    const pagination = data?.data?.meta;
    if (pagination) {
      setPageIndex({
        page: pagination?.page,
        hasNextPage: pagination?.hasNextPage,
        hasPreviousPage: pagination?.hasPreviousPage,
        pageCount: pagination?.pageCount,
      });
    }
  }, [data])

  useEffect(() => {
    if (filterCompanies?.length) {
      setFilterParams(filterCompanies.map((companyId) => `companyId[]=${companyId}`).join('&'))
    } else {
      setFilterParams("")
    }

    setPageIndex({
      ...pageIndex,
      page: 1
    });
  }, [filterCompanies, userRole, activeStatus])

  useEffect(() => {
    setPageIndex({
      ...pageIndex,
      page: 1
    });
  }, [userRole, activeStatus])

  const actionComponent = (row: RowType) => {
    return (
      <Box color={"#0A47BA"} display={"flex"}>
        <Box
          display={"flex"}
          alignItems={"center"}
          mr={"20px"}
          cursor={"pointer"}
          onClick={() => {
            setIdUser(row as any);
            onOpenEdit();
          }}
        >
          <Icon as={FiEdit3} width={"20px"} height={"20px"} mr={"5px"} />
          <Text fontSize={"18px"} fontFamily={"Avenir Heavy"}>
            Edit
          </Text>
        </Box>

        <Box
          display={"flex"}
          alignItems={"center"}
          cursor={"pointer"}
          onClick={() => {
            setIdUser(row as any);
            onOpenConfirmDelete();
          }}
        >
          <Icon
            as={RiDeleteBinLine}
            width={"20px"}
            height={"20px"}
            mr={"5px"}
          />
          <Text fontSize={"18px"} fontFamily={"Avenir Heavy"}>
            Delete
          </Text>
        </Box>
      </Box>
    );
  };

  const statusAction = (row: RowType) => {
    return (
      <Text
        fontSize={"18px"}
        fontFamily={"Avenir Medium"}
        color={row?.active ? "#0A47BA" : "#E74D3D"}
      >
        {row?.active ? "Active" : "Inactive"}
      </Text>
    );
  };

  const convertRole = (row: RowType) => {
    return (
      <Text
        fontSize={"18px"}
      >
        {row?.role?.name}
      </Text>
    );
  };

  const convertName = (row: RowType) => {
    if (!row?.firstName || !row?.lastName)
      return (
        <Text
          textDecoration={"underline"}
          w={"fit-content"}
          fontSize={"17px"}
          fontFamily={"Avenir Heavy"}
          color={"#0A47BA"}
        >
          Default
        </Text>
      );
    return (
      <Text
        textDecoration={"underline"}
        w={"fit-content"}
        fontSize={"17px"}
        fontFamily={"Avenir Heavy"}
        color={"#0A47BA"}
      >
        {`${row?.firstName} ${row?.lastName}`}
      </Text>
    );
  };

  const convertCompany = (row: RowType) => {
    return (
      <Tooltip label={row?.company?.companyName}>
        <Text
          w={"fit-content"}
          fontSize={"18px"}
          fontFamily={"Avenir Book"}
          maxW={"150px"}
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
        >
          {row?.company?.companyName}
        </Text>
      </Tooltip>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "User",
        accessor: (row: RowType) => convertName(row),
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Company",
        accessor: (row: RowType) => convertCompany(row),
      },
      {
        Header: "Mobile number",
        accessor: "phoneNumber",
      },
      {
        Header: "Role",
        accessor: (row: RowType) => convertRole(row),
      },
      {
        Header: "Status",
        accessor: (row: RowType) => statusAction(row),
      },
      {
        Header: "Action",
        accessor: (row: RowType) => actionComponent(row),
      },
    ],
    []
  );

  return (
    <Box w={"full"}>
      <Box
        w={"full"}
        h={"full"}
        bgColor={"#ffffff"}
        overflow={"hidden"}
        p={"74px 40px 74px 40px"}
      >
        <Box
          mb={"15px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Text
            fontSize={"38px"}
            lineHeight={"52px"}
            fontWeight={"500"}
            color={"#171E38"}
            w={"fit-content"}
            fontFamily={"Avenir Black"}
          >
            User settings
          </Text>
          <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"} gap={"14px"}>
            <Button
              variant={"solid"}
              // mr={"8px"}
              bgColor={"#0A47BA"}
              color={"#FFFFFF"}
              borderRadius={"37px"}
              height={"55px"}
              w={"154px"}
              fontSize={"20px"}
              _hover={{
                bgColor: "#0A47BA",
                opacity: "0.8",
              }}
              leftIcon={
                <Icon as={HiPlus} width={"22px"} height={"22px"} mr={"8px"} />
              }
              onClick={onOpen}
            >
              Create
            </Button>

            <Button
              variant={"outline"}
              color={"#124BB7"}
              borderRadius={"37px"}
              borderColor={"#124BB7"}
              height={"55px"}
              w={"154px"}
              isLoading={isExportLoading}
              isDisabled={isExportLoading}
              fontSize={"20px"}
              _hover={{
                opacity: "0.8",
              }}
              onClick={handleExportAction}
            >
              <DownloadIcon />
              <Text ml={"16px"}>Export</Text>
            </Button>
          </Box>
        </Box>
        <Flex mb={"40px"} alignItems={"center"} gap={"8px"} flexWrap={"wrap"}>
          <MultiSelectComponent
            list={userRole}
            setList={setUserRole}
            title={
              userRole?.filter((status) => status.check)?.length
                !== 1
                ? "All Roles"
                : `${userRole?.filter((status) => status.check).length} role selected`
            }
          />
          <MultiSelectComponent
            list={activeStatus}
            setList={setActiveStatus}
            title={
              activeStatus?.filter((status) => status.check).length ?
                activeStatus?.filter((status) => status.check).length
                  > 1
                  ? `${activeStatus?.filter((status) => status.check).length} statuses selected`
                  : `${activeStatus?.filter((status) => status.check).length} status selected`
                : "All Statuses"
            }
          />
        </Flex>
        {data?.data?.data && (
          <TableComponent
            isLoading={false}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            columns={columns as any}
            data={[...data.data.data]}
            name={"user-settings"}
          />
        )}
      </Box>

      {isOpen && (
        <AddNewUserComponent
          isOpen={isOpen}
          onClose={onClose}
          refetch={refetch}
        />
      )}
      {isOpenEdit && (
        <UpdateUserComponent
          isOpen={isOpenEdit}
          onClose={onCloseEdit}
          refetch={refetch}
          currentUser={idUser as any}
        />
      )}
      {isOpenConfirmDelete && (
        <ConfirmDeleteUserComponent
          idUser={(idUser as any)?.id}
          isOpen={isOpenConfirmDelete}
          onClose={onCloseConfirmDelete}
          setOpenSuccess={onOpenSuccess}
          refetch={refetch}
        />
      )}
      {isOpenSuccess && (
        <SuccessDeleteComponent
          statusSuccess="User successfully deleted"
          isOpen={isOpenSuccess}
          onClose={onCloseSuccess}
        />
      )}
    </Box>
  );
};

export default UsersSettingComponent;

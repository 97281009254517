import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  Input,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useRef, useEffect } from "react";
import { FormValues } from "./update-user.type";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./update-user.schema";
import { useGetUserRolesQuery, useUpdateUserMutation } from "src/hook/user/user";
import { getToastAttribute, ToastAttribute } from "src/constants";
import { useGetListCompanyQuery } from "src/hook/company/company";
interface Props {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  currentUser: FormValues;
}

const UpdateUserComponent = (props: Props) => {
  const { isOpen, onClose, refetch, currentUser } = props;

  const cancelRef = useRef();
  const toast = useToast();
  const showToast = (props: ToastAttribute) => {
    toast(getToastAttribute(props));
  };

  const { data: userRoles } = useGetUserRolesQuery();
  const [updateUser, { isLoading }] = useUpdateUserMutation();
  const { data: companies } =
    useGetListCompanyQuery();

  const {
    register,
    handleSubmit,
    reset: resetForm,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      firstName: currentUser?.firstName,
      lastName: currentUser?.lastName,
      phoneNumber: currentUser?.phoneNumber,
      role: currentUser?.role,
      active: currentUser?.active ? "true" : "false",
      company: { id: "" },
    },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const handleSubmitForm = (data: FormValues) => {
    updateUser({ body: { ...data, active: data.active === "true" ? true : false, companyId: data?.company?.id }, id: currentUser?.id })
      .unwrap()
      .then(() => {
        showToast({
          title: "User updated.",
          dis: "User updated successfully.",
        });
        resetForm();
        refetch();
        onClose();
      })
      .catch((err) => {
        showToast({
          title: "User update.",
          dis: err?.data?.message,
          sts: "error",
        });
      });
  };

  useEffect(() => {
    if (currentUser) {
      const newUser: FormValues = {
        ...currentUser,
        active: currentUser.active ? "true" : "false",
      };
      resetForm(newUser);
    }
  }, [currentUser]);

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef as any}
        onClose={onClose}
      >
        <AlertDialogOverlay bg={"#052A6E"} opacity={"0.7 !important"} />
        <AlertDialogContent
          minW={"56%"}
          color={"#171E38"}
          bg={"#F2F5FC"}
          top={"50%"}
          // left={"50%"}
          transform={"translateY(-50%) !important"}
          m={0}
        >
          <AlertDialogCloseButton
            _focusVisible={{
              outline: "none",
              border: "none",
            }}
            size={"lg"}
          />
          <AlertDialogBody pt={"38px"} pl={"40px"}>
            <Text
              fontSize={"40px"}
              fontFamily={"Avenir Black"}
              mb={"39px"}
              w={"fit-content"}
            >
              Edit user
            </Text>
            <form onSubmit={handleSubmit(handleSubmitForm)}>
              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  First name<span style={{ color: "#E74D3D" }}>*</span>
                </Text>
                <Box w={"60%"}>
                  <Input
                    border={"0px"}
                    bg={"white"}
                    borderRadius={"10px"}
                    height={"45px"}
                    fontFamily={"Avenir Book"}
                    fontSize={"18px"}
                    {...register("firstName", {
                      shouldUnregister: true,
                    })}
                  />
                  {errors.firstName && (
                    <Text
                      fontSize={"12px"}
                      mt={"4px"}
                      ml={"4px"}
                      color={"red.400"}
                      fontFamily={"Avenir Medium"}
                    >
                      {errors.firstName.message}
                    </Text>
                  )}
                </Box>
              </Box>

              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  Last name<span style={{ color: "#E74D3D" }}>*</span>
                </Text>
                <Box w={"60%"}>
                  <Input
                    border={"0px"}
                    bg={"white"}
                    borderRadius={"10px"}
                    height={"45px"}
                    fontFamily={"Avenir Book"}
                    fontSize={"18px"}
                    {...register("lastName", {
                      shouldUnregister: true,
                    })}
                  />
                  {errors.lastName && (
                    <Text
                      fontSize={"12px"}
                      mt={"4px"}
                      ml={"4px"}
                      color={"red.400"}
                      fontFamily={"Avenir Medium"}
                    >
                      {errors.lastName.message}
                    </Text>
                  )}
                </Box>
              </Box>
              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  Mobile number
                </Text>
                <Input
                  w={"60%"}
                  border={"0px"}
                  bg={"white"}
                  borderRadius={"10px"}
                  height={"45px"}
                  fontFamily={"Avenir Book"}
                  fontSize={"18px"}
                  {...register("phoneNumber", {
                    shouldUnregister: true,
                  })}
                />
              </Box>

              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  Role
                </Text>
                <Select
                  w={"60%"}
                  border={"0px"}
                  bg={"white"}
                  borderRadius={"10px"}
                  variant={"base"}
                  size={"lg"}
                  textTransform={"capitalize"}
                  height={"45px"}
                  fontFamily={"Avenir Book"}
                  fontSize={"18px"}
                  {...register("role", {
                    shouldUnregister: true,
                  })}
                >
                  {userRoles?.data?.map(role => {
                    return <option key={role.id} value={role.id} selected={role.id === currentUser?.role?.id}>{role.name}</option>
                  })}
                </Select>
              </Box>
              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  Company name
                </Text>
                <Box w={"60%"}>
                  <Select
                    border={"0px"}
                    bg={"white"}
                    borderRadius={"10px"}
                    variant={"base"}
                    size={"lg"}
                    textTransform={"capitalize"}
                    height={"45px"}
                    fontFamily={"Avenir Book"}
                    fontSize={"18px"}
                    {...register("company.id", {
                      shouldUnregister: true,
                    })}
                  >
                    {companies?.data?.map((item) => (
                      item?.companyName && <option key={item?.id} value={item?.id}>{item?.companyName}</option>
                    ))}
                  </Select>
                  {errors.company && (
                    <Text
                      fontSize={"12px"}
                      mt={"4px"}
                      ml={"4px"}
                      color={"red.400"}
                      fontFamily={"Avenir Medium"}
                    >
                      {errors.company.message}
                    </Text>
                  )}
                </Box>
              </Box>
              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  Status
                </Text>

                <Select
                  w={"60%"}
                  border={"0px"}
                  bg={"white"}
                  borderRadius={"10px"}
                  variant={"base"}
                  size={"lg"}
                  textTransform={"capitalize"}
                  height={"45px"}
                  fontFamily={"Avenir Book"}
                  fontSize={"18px"}
                  {...register("active", {
                    shouldUnregister: true,
                  })}
                >
                  <option value={"true"}>Active</option>
                  <option value={"false"}>Inactive</option>
                </Select>
              </Box>

              <Box
                mt={"40px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                fontFamily={"Avenir Black"}
                mb={"25px"}
              >
                <Button
                  type="submit"
                  variant={"solid"}
                  bg={"#0A47BA"}
                  w={"191px"}
                  height={"55px"}
                  borderRadius={"37px"}
                  mr={"16px"}
                  color={"#FFFFFF"}
                  fontSize={"20px"}
                  _hover={{
                    bg: "#0A47BA",
                    opacity: 0.8,
                  }}
                  isLoading={isLoading}
                >
                  Update
                </Button>
              </Box>
            </form>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default UpdateUserComponent;

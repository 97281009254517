export enum ChargeStationApi {
  GET_LIST_CHARGE_STATION = "/charge-station",
  GET_CHARGE_STATION = "/charge-station",
  CREATE_CHARGE_STATION = "/charge-station",
  UPDATE_CHARGE_STATION = "/charge-station",
  DELETE_CHARGE_STATION = "/charge-station",
  ATTACH_SIMULATOR = "/charge-station/attach-simulator",
  TERMINATE_SIMULATOR = "/charge-station/terminate-simulator",
  GET_LIST_CHARGE_STATION_LOCATION = '/charge-station/location',
  QR_CODE = "/charge-station/qr-code",
  DOWNLOAD_CSV = "/charge-station/download/csv"
}

export interface ILOCATION_PARAMS {
  value: string;
  check?: boolean;
}

interface FilterType {
  value: string;
  label: string;
  check: boolean;
}

export interface BODY_GET_LIST_CHARGE_STATION {
  take?: number | "full";
  page?: number | "full";
  companyId?: string;
  companyIdList?: string[];
  listLocation?: FilterType[];
  statusList?: string[];
}

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  Grid,
  GridItem,
  Input,
  Select,
  Text,
  useToast,
  Flex, 
  Spinner
} from "@chakra-ui/react";
import { useRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// import { ReactComponent as SearchIcon } from "src/assets/icon/search-icon.svg";
import { schema } from "./update-location.schema";
import { FormValues } from "./update-location.type";
import { useUpdateLocationMutation } from "src/hook/location/location";
import { RowType } from "src/template/network/location/location.type";
import { useGetListCountryQuery } from "src/hook/country/country";
import { getListTimezone } from "src/constants/timezone";
import { getToastAttribute, ToastAttribute } from "src/constants";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  currentLocation: FormValues;
  refetch?: () => void;
  setListLocation: any;
  setPageIndex: any;
  listLocation: RowType[];
}

const UpdateLocationComponent = (props: Props) => {
  const { isOpen, onClose, currentLocation, setListLocation } = props;

  const cancelRef = useRef();
  const toast = useToast();
  const showToast = (props: ToastAttribute) => {
    toast(getToastAttribute(props));
  };
  const [listTimezone, setListTimezone] = useState<string[]>([]);
  const [updateLocation, { isLoading }] = useUpdateLocationMutation();
  const { data: countryData, isFetching } = useGetListCountryQuery();

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isValid, isDirty },
  } = useForm<FormValues>({
    defaultValues: {
      name: "",
      long: 0,
      lat: 0,
      timezone: "UTC+08:00",
      address: {
        streetNumber: "",
        postalCode: "",
        city: "",
        state: "",
        country: "",
        countryId: "",
      },
      notes: "",
    },
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const handleSubmitForm = (data: FormValues) => {
    const selectedCountry = countryData?.data?.find(
      (item: any) => item.id === data.address.countryId
    );

    const dataItem = {
      ...data,
      address: {
        ...data.address,
        country: selectedCountry?.name,
        countryId: selectedCountry?.id,
      },
    };
    updateLocation({ body: dataItem, id: currentLocation.id })
      .unwrap()
      .then(() => {
        showToast({
          title: "Location updated.",
          dis: "Location is updated successfully.",
        });
        setListLocation((prevLocations) => {
          const updatedLocations = prevLocations.map((location) => {
            if (location.id === currentLocation.id) {
              return {
                ...location,
                name: data.name,
                long: data.long,
                lat: data.lat,
                timezone: data.timezone,
                address: {
                  ...location.address,
                  streetNumber: data.address.streetNumber,
                  postalCode: data.address.postalCode,
                  city: data.address.city,
                  state: data.address.state,
                  country: data.address.country,
                  countryId: data.address.countryId,
                },
                notes: data.notes,
              };
            }
            return location;
          });
          return updatedLocations;
        });
        reset();
        onClose();
      })
      .catch((err) => {
        showToast({
          title: "Location update.",
          dis: err?.data?.message,
          sts: "error",
        });
      });
  };

  useEffect(() => {
    if (currentLocation && countryData?.data) {
      reset({
        ...currentLocation,
        notes: currentLocation.notes === null ? "" : currentLocation.notes,
      });
    }
  }, [currentLocation, countryData]);

  useEffect(() => {
    setListTimezone(() => getListTimezone());
  }, []);

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef as any}
        onClose={onClose}
        
      >
        <AlertDialogOverlay bg={"#052A6E"} opacity={"0.7 !important"} />
        <AlertDialogContent
          minW={"56%"}
          color={"#171E38"}
          bg={"#F2F5FC"}
          top={"50%"}
          transform={"translateY(-50%) !important"}
          m={0}
        >
          <AlertDialogCloseButton
            _focusVisible={{
              outline: "none",
              border: "none",
            }}
            size={"lg"}
          />
          <AlertDialogBody pt={"38px"} pl={"40px"}>
            <Text
              fontSize={"40px"}
              fontFamily={"Avenir Black"}
              mb={"39px"}
              w={"fit-content"}
            >
              Edit location
            </Text>
            {isFetching ? (
              <Flex
              h={"120px"}
              w={"full"}
              justify={"center"}
              gap={"8px"}
              align={"center"}
            >
              <Spinner size="lg" color="blue.500" />
            </Flex>
            ) : (
              <form onSubmit={handleSubmit(handleSubmitForm)}>
              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  Name<span style={{ color: "#E74D3D" }}>*</span>
                </Text>
                <Box w={"60%"}>
                  <Input
                    border={"0px"}
                    bg={"white"}
                    borderRadius={"10px"}
                    height={"45px"}
                    fontFamily={"Avenir Book"}
                    fontSize={"18px"}
                    {...register("name", {
                      shouldUnregister: true,
                    })}
                  />
                  {errors.name && (
                    <Text
                      fontSize={"12px"}
                      mt={"4px"}
                      ml={"4px"}
                      color={"red.400"}
                      fontFamily={"Avenir Medium"}
                    >
                      {errors.name.message}
                    </Text>
                  )}
                </Box>
              </Box>

              {/* <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  Location<span style={{ color: "#E74D3D" }}>*</span>
                </Text>
                <Box w={"42%"} position={"relative"}>
                  <Input
                    w={"full"}
                    border={"0px"}
                    bg={"white"}
                    borderRadius={"10px"}
                    height={"45px"}
                    fontFamily={"Avenir Book"}
                    fontSize={"18px"}
                    pr={"45px"}
                    placeholder={"Search for address"}
                    _placeholder={{
                      color: "#171E38",
                    }}
                    value={"Default"}
                    isDisabled={true}
                  />
                  <Box
                    position={"absolute"}
                    right={"16px"}
                    top={"50%"}
                    transform={"translateY(-50%)"}
                    zIndex={2}
                  >
                    <SearchIcon stroke="#020E20" />
                  </Box>
                  {(errors.long || errors.lat) && (
                    <Text
                      fontSize={"12px"}
                      mt={"4px"}
                      ml={"4px"}
                      color={"red.400"}
                      fontFamily={"Avenir Medium"}
                    >
                      Location is a required field
                    </Text>
                  )}
                </Box>
              </Box> */}

              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  Address<span style={{ color: "#E74D3D" }}>*</span>
                </Text>
                <Box w={"60%"}>
                  <Grid
                    templateRows="repeat(3, 1fr)"
                    templateColumns="repeat(2, 1fr)"
                    gap={"8px"}
                  >
                    <GridItem rowSpan={1} colSpan={2}>
                      <Input
                        border={"0px"}
                        bg={"white"}
                        borderRadius={"10px"}
                        height={"45px"}
                        fontFamily={"Avenir Book !important"}
                        fontSize={"18px"}
                        placeholder={"Street name and number"}
                        _placeholder={{
                          color: "#171E38",
                        }}
                        {...register("address.streetNumber", {
                          shouldUnregister: true,
                        })}
                      />
                    </GridItem>
                    <GridItem colSpan={1}>
                      <Input
                        border={"0px"}
                        bg={"white"}
                        borderRadius={"10px"}
                        height={"45px"}
                        fontFamily={"Avenir Book"}
                        fontSize={"18px"}
                        placeholder={"City"}
                        _placeholder={{
                          color: "#171E38",
                        }}
                        {...register("address.city", {
                          shouldUnregister: true,
                        })}
                      />
                    </GridItem>
                    <GridItem colSpan={1}>
                      <Input
                        border={"0px"}
                        bg={"white"}
                        borderRadius={"10px"}
                        height={"45px"}
                        fontFamily={"Avenir Book"}
                        fontSize={"18px"}
                        placeholder={"Zip code"}
                        _placeholder={{
                          color: "#171E38",
                        }}
                        {...register("address.postalCode", {
                          shouldUnregister: true,
                        })}
                      />
                    </GridItem>
                    <GridItem colSpan={1}>
                      <Input
                        border={"0px"}
                        bg={"white"}
                        borderRadius={"10px"}
                        height={"45px"}
                        fontFamily={"Avenir Book"}
                        fontSize={"18px"}
                        placeholder={"State"}
                        _placeholder={{
                          color: "#171E38",
                        }}
                        {...register("address.state", {
                          shouldUnregister: true,
                        })}
                      />
                    </GridItem>
                    <GridItem colSpan={1}>
                      <Select
                        border={"0px"}
                        bg={"white"}
                        borderRadius={"10px"}
                        variant={"base"}
                        size={"lg"}
                        textTransform={"capitalize"}
                        height={"45px"}
                        fontFamily={"Avenir Book"}
                        fontSize={"18px"}
                        {...register("address.countryId", {
                          shouldUnregister: true,
                        })}
                      >
                        {countryData?.data?.map((item: any) => (
                          <option key={item?.id} value={item?.id}>
                            {item?.name}
                          </option>
                        ))}
                      </Select>
                    </GridItem>
                  </Grid>
                  {errors.address && (
                    <Text
                      fontSize={"12px"}
                      mt={"4px"}
                      ml={"4px"}
                      color={"red.400"}
                      fontFamily={"Avenir Medium"}
                    >
                      {errors.address.message}
                    </Text>
                  )}
                </Box>
              </Box>

              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  Timezone<span style={{ color: "#E74D3D" }}>*</span>
                </Text>
                <Box w={"60%"}>
                  <Select
                    border={"0px"}
                    bg={"white"}
                    borderRadius={"10px"}
                    variant={"base"}
                    size={"lg"}
                    textTransform={"capitalize"}
                    height={"45px"}
                    fontFamily={"Avenir Book"}
                    fontSize={"18px"}
                    value={getValues("timezone")}
                    {...register("timezone")}
                  >
                    {listTimezone?.map((item, index) => (
                      <option key={index}>{item}</option>
                    ))}
                  </Select>
                  {errors.timezone && (
                    <Text
                      fontSize={"12px"}
                      mt={"4px"}
                      ml={"4px"}
                      color={"red.400"}
                      fontFamily={"Avenir Medium"}
                    >
                      {errors.timezone.message}
                    </Text>
                  )}
                </Box>
              </Box>

              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  Notes
                </Text>
                <Input
                  w={"60%"}
                  border={"0px"}
                  bg={"white"}
                  borderRadius={"10px"}
                  height={"45px"}
                  fontFamily={"Avenir Book"}
                  fontSize={"18px"}
                  {...register("notes")}
                />
              </Box>

              <Box
                mt={"40px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                fontFamily={"Avenir Black"}
                mb={"25px"}
              >
                <Button
                  onClick={() => {
                    reset({
                      ...currentLocation,
                      notes:
                        currentLocation.notes === null
                          ? ""
                          : currentLocation.notes,
                    });
                  }}
                  variant={"outline"}
                  borderColor={"#020E20"}
                  w={"191px"}
                  height={"55px"}
                  borderRadius={"37px"}
                  mr={"16px"}
                  fontSize={"20px"}
                >
                  Reset
                </Button>
                <Button
                  type="submit"
                  variant={"solid"}
                  bg={"#0A47BA"}
                  w={"191px"}
                  height={"55px"}
                  borderRadius={"37px"}
                  mr={"16px"}
                  color={"#FFFFFF"}
                  fontSize={"20px"}
                  _hover={{
                    bg: "#0A47BA",
                    opacity: 0.8,
                  }}
                  isDisabled={!isValid || !isDirty || isLoading}
                  isLoading={isLoading}
                  _disabled={{
                    bg: "#BEC3C7",
                    _hover: {
                      bg: "#BEC3C7",
                      opacity: "1",
                    },
                  }}
                >
                  Update
                </Button>
              </Box>
            </form>
            )}
            
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default UpdateLocationComponent;

import { RoleEnum } from "src/constants";

export interface RowType {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: { id: number; name: string };
  active: boolean;
  company: { id?: string; companyName?: string }
}

export const UserRoleList = [
  { value: String(RoleEnum.MANAGER), label: 'Manager', check: false },
  { value: String(RoleEnum.STAFF), label: 'Staff', check: false },
]

export const ActiveStatusList = [
  { value: 'active', label: 'Active', check: false },
  { value: 'inactive', label: 'Inactive', check: false },
]
import * as yup from "yup";

export const schema = yup.object().shape({
  role: yup.number(),
  company: yup.object().when('role', (role: any) => {
    if (role[0] === 1 || role === 1) {
      return yup.object().shape({
        id: yup.string().trim()
      }).test("company", "Company is not valid", function (value) {
        if (!value.id) {
          return this.createError({
            path: "company",
            message: "Company is a required field",
          });
        }
        return true;
      })
    }
    return yup.object().notRequired().strip()
  }),
  security: yup
    .object()
    .shape({
      type: yup.string(),
    }),
  location: yup
    .object()
    .shape({
      id: yup.string().required("This field is required"),
    }),
  name: yup.string().required("This field is required"),
  description: yup.string(),

});

import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReauth } from "src/services";

import { CountryApi } from "./country.type";

export const countryAPI = createApi({
  reducerPath: "countryAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getListCountry: builder.query<any, void>({
      query: () => {
        
        return {
          url: `${CountryApi.GET_COUNTRY}`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 0,
    })
  }),
});

export const {
    useGetListCountryQuery,
} = countryAPI;

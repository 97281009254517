import * as yup from "yup";

export const schema = yup.object().shape({
  name: yup
    .string()
    .max(255, "Field name cannot exceed 255 characters")
    .required("Name is a required field"),
  long: yup.number(),
  lat: yup.number(),
  address: yup
    .object()
    .shape({
      streetNumber: yup.string(),
      postalCode: yup.string(),
      city: yup.string(),
      state: yup.string(),
      country: yup.string(),
      countryId: yup.string(),
    })
    .test("address", "Address is not valid", function (value) {
      if (
        !value.streetNumber ||
        !value.postalCode ||
        !value.city ||
        !value.state ||
        !value.countryId 
      ) {
        return this.createError({
          path: "address",
          message: "All address fields must be filled in",
        });
      }
      return true;
    }),
  timezone: yup.string().required(),
  notes: yup.mixed(),
});

import { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  Spinner,
  Text,
  useOutsideClick,
} from "@chakra-ui/react";
import { ChevronDownIcon, CloseIcon, SearchIcon } from "@chakra-ui/icons";
import { useAppDispatch, useAppSelector } from "src/redux";

import useDebounce from "src/custom-hook/useDebound";
import { useGetListCompanyQuery } from "src/hook/company/company";
import { CompanyType } from "src/hook/company/company.type";
import { handleGlobalFilter } from "src/hook/header/header";

interface CompanyCheckedType extends CompanyType {
  checked: boolean;
}
export const CompanyFilter = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredList, setFilteredList] = useState<CompanyCheckedType[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const menuRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const dispatch = useAppDispatch();
  const filteredCompanies = useAppSelector(
    (state) => state?.headerFilter?.companyId
  );
  const debouncedSearch = useDebounce(search, 500);

  const { data: companies, isFetching: isCompaniesFetching } =
    useGetListCompanyQuery();

  useOutsideClick({
    ref: menuRef,
    handler: () => setMenuOpen(false),
  });

  const handleClick = (id: string) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((selected) => selected !== id));
      dispatch(
        handleGlobalFilter({
          companyId: selectedItems.filter((selected) => selected !== id),
        })
      );
    } else {
      setSelectedItems([...selectedItems, id]);
      dispatch(handleGlobalFilter({ companyId: [...selectedItems, id] }));
    }
  };

  const handleFilter = (value: string) => {
    if (value.trim().length) {
      const items = companies.data.filter((company) =>
        company?.companyName?.toLowerCase().includes(value.trim().toLowerCase())
      );
      setFilteredList(() => items);
    } else {
      setFilteredList(() => companies?.data);
    }
  };

  const handleUnselectAll = () => {
    setSelectedItems(() => []);
    dispatch(
      handleGlobalFilter({
        companyId: [],
      })
    );
  };

  useEffect(() => {
    if (companies?.data && filteredCompanies) {
      const updatedList = companies.data.map((company) => ({
        ...company,
        checked: filteredCompanies.includes(company.id),
      }));
      setFilteredList(() => updatedList);
      setSelectedItems(() => filteredCompanies);
    } else {
      setFilteredList(() => companies?.data || []);
    }
  }, [companies]);

  useEffect(() => {
    handleFilter(debouncedSearch);
  }, [debouncedSearch]);

  return (
    <Flex gap={"8px"} ref={menuRef}>
      <Menu closeOnSelect={false} isOpen={isMenuOpen}>
        <MenuButton
          as={Button}
          colorScheme="white"
          _hover={{
            opacity: 0.9,
          }}
          borderRadius={"29px"}
          width={"320px"}
          height={"55px"}
          position={"relative"}
          overflow={"hidden"}
          borderWidth={"1px"}
          borderColor={"#0A47BA"}
          backgroundColor={"white"}
          boxSizing={"border-box"}
          outline={"none"}
          display="flex"
          onClick={() => {
            setMenuOpen((pre) => !pre);
          }}
        >
          <Box display={"flex"} height={"full"}>
            <Text color={"black"} fontFamily={"Avenir Book"} fontWeight="300">
              {selectedItems?.length
                ? selectedItems?.length > 1
                  ? `${selectedItems?.length} companies selected`
                  : `${selectedItems?.length} company selected`
                : "All companies"}
            </Text>
          </Box>
          <Box
            position={"absolute"}
            top={"0px"}
            height={"full"}
            bg={"#0A47BA"}
            w={"65px"}
            right="0px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ChevronDownIcon border="0px" width={"32px"} height={"32px"} />
          </Box>
        </MenuButton>
        <MenuList
          width="320px"
          zIndex={1000}
          borderRadius={"8px"}
          style={{ padding: 0 }}
        >
          {isCompaniesFetching ? (
            <Flex
              h={"120px"}
              w={"full"}
              justify={"center"}
              gap={"8px"}
              align={"center"}
            >
              <Spinner size="lg" color="blue.500" />
              <Text>Loading...</Text>
            </Flex>
          ) : (
            <MenuOptionGroup width="320px" type="checkbox">
              <Box px={"8px"} pt="10px">
                <InputGroup size="md">
                  <Input
                    ref={inputRef}
                    placeholder="Search"
                    outline="none"
                    borderWidth={"2px"}
                    borderRadius={"8px"}
                    _focus={{
                      outline: "none",
                      borderColor: "#0A47BA",
                      outlineColor: "",
                      boxShadow: "none",
                    }}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    pr={"50px"}
                    _hover={{ borderColor: "#0A47BA" }}
                  />
                  <InputRightElement mr={"10px"}>
                    {search ? (
                      <CloseIcon
                        border="0px"
                        width={"20px"}
                        height={"20px"}
                        color={"#0A47BA"}
                        onClick={() => setSearch("")}
                        cursor="pointer"
                      />
                    ) : (
                      <SearchIcon
                        border="0px"
                        width={"20px"}
                        height={"20px"}
                        color={"#0A47BA"}
                      />
                    )}
                  </InputRightElement>
                </InputGroup>
              </Box>
              <Box
                maxH={"350px"}
                overflow={"auto"}
                css={{
                  "&::-webkit-scrollbar": {
                    width: "6px",
                  },
                  "&::-webkit-scrollbar-track": {
                    width: "6px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "gray",
                    borderRadius: "20px",
                  },
                }}
              >
                {selectedItems?.length ? (
                  <Box
                    px="10px"
                    py="4px"
                    _hover={{ bgColor: "gray.100" }}
                    cursor="pointer"
                    onClick={handleUnselectAll}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <CloseIcon
                      border="0px"
                      width={"15px"}
                      height={"15px"}
                      color={"#0A47BA"}
                      mr={"8px"}
                    />
                    <Text
                      whiteSpace={"nowrap"}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      maxW={"270px"}
                      fontWeight={600}
                    >
                      Unselect All
                    </Text>
                  </Box>
                ) : null}
                {filteredList?.length > 0 ? (
                  <CheckboxGroup colorScheme="#0A47BA">
                    {filteredList?.map((company: any) => (
                      <Box
                        key={company?.id}
                        px="10px"
                        py="4px"
                        _hover={{ bgColor: "gray.100" }}
                      >
                        <Checkbox
                          size="md"
                          w={"full"}
                          isChecked={selectedItems.includes(company.id)}
                          iconColor="#0A47BA"
                          onChange={() => handleClick(company.id)}
                        >
                          <Text
                            whiteSpace={"nowrap"}
                            overflow={"hidden"}
                            textOverflow={"ellipsis"}
                            maxW={"270px"}
                          >
                            {company?.companyName}
                          </Text>
                        </Checkbox>
                      </Box>
                    ))}
                  </CheckboxGroup>
                ) : (
                  <Flex p={"10px"} justifyContent={"center"}>
                    <Text>No data</Text>
                  </Flex>
                )}
              </Box>
            </MenuOptionGroup>
          )}
        </MenuList>
      </Menu>
    </Flex>
  );
};

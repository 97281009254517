export enum CP_ACTION {
    HEARTBEAT = 'Heartbeat',
    START_TRANSACTION = 'StartTransaction',
    STOP_TRANSACTION = 'StopTransaction',
    BOOT_NOTIFICATION = 'BootNotification',
    // STATUS_NOTIFICATION = 'StatusNotification',
    METER_VALUES = 'MeterValues',
}

export const LogsMessages = [
    { value: CP_ACTION.HEARTBEAT, label: CP_ACTION.HEARTBEAT, check: false },
    { value: CP_ACTION.START_TRANSACTION, label: CP_ACTION.START_TRANSACTION, check: false },
    { value: CP_ACTION.STOP_TRANSACTION, label: CP_ACTION.STOP_TRANSACTION, check: false },
    { value: CP_ACTION.BOOT_NOTIFICATION, label: CP_ACTION.BOOT_NOTIFICATION, check: false },
    // { value: CP_ACTION.STATUS_NOTIFICATION, label: CP_ACTION.STATUS_NOTIFICATION, check: false },
    { value: CP_ACTION.METER_VALUES, label: CP_ACTION.METER_VALUES, check: false },
]